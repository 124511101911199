module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#000000","description":"Portfolio of Michael Whittet, a Front End Developer based just outside of London in Essex","display":"standalone","icon":"src/images/icon.png","icons":[{"src":"src/images/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"src/images/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"any"},{"src":"src/images/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"src/images/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"any"},{"src":"src/images/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"src/images/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"maskable"},{"src":"src/images/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"src/images/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"name":"Michael Whittet's Portfolio","short_name":"MW's Portfolio","start_url":"/","theme_color":"#000000","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"61888172a34261faa1fbe5ec5e2af1fa"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
